// register component as global
// define component naming with kebab-case
const announcementmarqueeCmp = Vue.component("announcement-marquee-component", {
  template: "#announcementmarqueeTemplate",
  data: function () {
    return {
      htmlMsg: "",
    };
  },
  mounted() {
    var self = this;

    axios({
      method: "GET",
      url: "/index/index/htmlContent?contentType=announcement",
    }).then(function (response) {
      console.log("htmlContent:", response);
      if (response.data.code === 0) {
        const data = response.data.data;
        self.htmlMsg = data.content;
      }
    });
  },
});
