// register component as global
// define component naming with kebab-case
const orderdataboxCmp = Vue.component("orderdatabox-component", {
  template: `<el-row>
    <el-col :span="12">
      <el-card style="margin: 10px" class="home_dynamic_block">
        <el-row>
          <el-col :span="24" data-lang="OnlineUsers"></el-col>
          <el-col :span="24">
            <h2>{{orderInfo.home_activeusers_count}}</h2>
          </el-col>
          <el-col :span="24">
            <span class="primary-color"
              >{{orderInfo.home_activeusers_percent}}%</span
            >
            &nbsp;<span data-lang="FromLastMonth"></span>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card style="margin: 10px" class="home_dynamic_block">
        <el-row>
          <el-col :span="24" data-lang="OptimizeDemand"></el-col>
          <el-col :span="24">
            <h2>{{orderInfo.home_optimizationreq_count}}K</h2>
          </el-col>
          <el-col :span="24">
            <span class="primary-color"
              >{{orderInfo.home_optimizationreq_percent}}%</span
            >
            &nbsp;<span data-lang="FromLastMonth"></span>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card style="margin: 10px" class="home_dynamic_block">
        <el-row>
          <el-col :span="24" data-lang="OrderCompletion"></el-col>
          <el-col :span="24" style="padding-top: 10px">
            <span style="font-weight: bold; font-size: 1.5rem"
              >{{orderInfo.home_completeorder_count}}</span
            >
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card style="margin: 10px" class="home_dynamic_block">
        <el-row>
          <el-col :span="24" data-lang="OrderQuantity"></el-col>
          <el-col :span="24" style="padding-top: 10px">
            <span style="font-weight: bold; font-size: 1.5rem"
              >{{orderInfo.home_orderqty_count}}</span
            >
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>`,
  data: function () {
    return {
      orderInfo: {
        intervalMinutes: 30,
        diff: 0.325,
        home_completeorder_count: 41900,
        home_orderqty_count: 7484,
        home_activeusers_count: 17972,
        home_optimizationreq_count: 127,
        home_activeusers_percent: 35,
        home_optimizationreq_percent: 23,
      },
    };
  },
  mounted() {
    var self = this;

    axios({
      method: "GET",
      url: "/index/index/orderDataInfo",
    }).then(function (response) {
      console.log("htmlContent:", response);
      if (response.data.code === 0) {
        const data = response.data.data;
        self.orderInfo = data.orderInfo;
      }
    });
  },
});
