// register component as global
// define component naming with kebab-case
const languageDropDownMenuCmp = Vue.component(
  "languagedropdown-menu-component",
  {
    template: "#languageDropDownMenuTemplate",
    data: function () {
      return {
        selectedMenuItem: {},
        subMenuItems: [],
        defaultLanguages: [{}],
      };
    },
    methods: {
      changeLanguage(langCode) {
        console.log("changeLanguage", langCode);
        newLang.setLang(langCode);
        window.location.reload();
      },
    },
    created() {
      const self = this;
      const singleLangCode = self.langCode;
      self.selectedMenuItem = uiConfig.info.languages.filter((item, index) => {
        return item.code === singleLangCode;
      })[0];

      axios({
        method: "GET",
        url: "/index/user/lang",
      }).then(function (response) {
        console.log("/index/user/lang", response);
        if (response.data.code == 0) {
          const langCodes = response.data.data.map((e) => {
            return e.langCode;
          });

          self.defaultLanguages = uiConfig.info.languages.filter((e) => {
            return langCodes.includes(e.code);
          });

          self.subMenuItems = self.defaultLanguages.filter((item, index) => {
            return item.code !== singleLangCode;
          });
        } else {
          that.$message({
            message: response.data.info,
            type: "warning",
          });
        }
      });
    },
  }
);
