// register component as global
// define component naming with kebab-case
const userProfileCardCmp = Vue.component("userprofile-card-component", {
  template: "#userprofilecardTemplate",
  data: function () {
    return {
      isInvitationEnabled:
        companyInfo.isEnableInviteCode &&
        companyInfo.accountInfo.isVIPEnabledInvite,
      userProfileInfo: {
        userName: "",
        inviteCode: "",
        creditScore: 100,
        totalAmt: 0.0,
        totalDailyCommissionAmt: 0.0,
        vipLevel: 0,
      },
    };
  },
  mounted() {
    var self = this;

    axios({
      method: "GET",
      url: "/index/my/userprofilecard",
    }).then(function (response) {
      console.log("/index/my/userprofilecard:", response);
      if (response.data.code === 0) {
        const data = response.data.data;
        self.userProfileInfo = data;
      }
    });
  },
});
