// register component as global
// define component naming with kebab-case
const titlebarCmp = Vue.component("title-bar-component", {
  template: `<el-row><el-col :span="24"><div class="section-title"><span :data-lang="title"></span></div></el-col></el-row>`,
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
