// register component as global
// define component naming with kebab-case
const shortButtonRowCmp = Vue.component("shortbuttonrow-component", {
  template: `<el-row>
      <el-col :span="24">
        <div class="section-flex">
          <span class="span-button1" @click="to_faqs">FAQ</span>
          <span class="span-button1" @click="to_cert">VIP</span>
          <span class="span-button1" @click="to_tandc">T&C</span>
        </div>
      </el-col>
    </el-row>`,
  methods: {
    to_tandc() {
      console.log("to_tandc");
      window.location.href = "/index/my/hdetail.html?id=tnc";
    },
    to_faqs() {
      console.log("to_tanc");
      window.location.href = "/index/my/hdetail.html?id=faq";
    },
    to_cert() {
      console.log("to_cert");
      window.location.href = "/index/my/hdetail.html?id=latestevent";
    },
  },
});
