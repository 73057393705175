// register component as global
// define component naming with kebab-case
const orderLoadingDialogModalCmp = Vue.component(
  "orderloading-dialogmodal-component",
  {
    template: `<el-dialog
  top="..."
  custom-class="dialog_margin_loading"
  :title="translateWord('OrderDetails')"
  :visible.sync="visible"
  width="320px"
>
  <div class="" style="margin: 10px 0">
    <p style="margin: auto" v-html="msg_progress_title"></p>
  </div>
  <div class="my-flex my-flex-center" style="margin-bottom: 20px">
    <div style="display: inline-flex">
      <div :class="'random-img product-'+randomImg1"></div>
      <div :class="'random-img product-'+randomImg2"></div>
      <div :class="'random-img product-'+randomImg3"></div>
    </div>
  </div>
  <el-divider></el-divider>
  <div class="my-flex my-flex-center" v-if="false"></div>
  <div style="height: 100px" v-else>
    <div
      style="line-height: 25px"
      class="loading_order_detail_progress_tips"
      v-html="loading_progress1"
    ></div>
    <div
      style="line-height: 25px"
      class="loading_order_detail_progress_tips"
      v-html="loading_progress2"
    ></div>
    <div
      style="line-height: 25px"
      class="loading_order_detail_progress_tips"
      v-html="loading_progress3"
    ></div>
    <div
      style="line-height: 25px"
      class="loading_order_detail_progress_tips"
      v-html="loading_progress4"
    ></div>
  </div>
</el-dialog>`,
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        msg_progress_title: "",
        loading_progress1: "",
        loading_progress2: "",
        loading_progress3: "",
        loading_progress4: "",
        randomImg1: "1",
        randomImg2: "2",
        randomImg3: "3",
      };
    },
    watch: {
      visible(val) {
        var that = this;
        if (val) {
          var progressNote1 = this.translateWord("msg_progress_note1");
          var progressNote2 = this.translateWord("msg_progress_note2");
          var progressNote3 = this.translateWord("msg_progress_note3");
          var progressNote4 = this.translateWord("msg_progress_note4");

          setTimeout(function () {
            that.loading_progress1 = progressNote1;
          }, 50);
          setTimeout(function () {
            that.loading_progress2 = progressNote2;
          }, 100);
          setTimeout(function () {
            that.loading_progress3 = progressNote3;
          }, 150);
          setTimeout(function () {
            that.loading_progress4 = progressNote4;
          }, 200);
        }
      },
    },
    methods: {
      randomImgNumber() {
        return Math.floor(Math.random() * 39) + 1;
      },
    },
    mounted() {
      console.log("onMounted orderloading");

      this.msg_progress_title = this.translateWord("msg_progress_title");

      let randomNums = [];

      for (var i = 0; i < 3; i++) {
        let randomNum = this.randomImgNumber();
        while (randomNums.includes(randomNum)) {
          randomNum = this.randomImgNumber();
        }
        randomNums.push(randomNum);
      }

      // auto generate random imgs
      this.randomImg1 = randomNums[0];
      this.randomImg2 = randomNums[1];
      this.randomImg3 = randomNums[2];
    },
    activated() {
      console.log("activated orderloading");
    },
    deactivated() {
      console.log("deactivated orderloading");
    },
    updated() {
      console.log("updated orderloading");
    },
    unmounted() {
      console.log("unmounted orderloading");
    },
  }
);
