// register component as global
// define component naming with kebab-case
const navbarCmp = Vue.component("navbar-component", {
  template: "#navbarTemplate",
  data: function () {
    return {
      isSoundEnabled: uiConfig.isSoundEnabled,
      isSoundOn: false,
    };
  },
  methods: {
    to_notify_message() {
      window.location.href = "/index/my/notification";
    },
    doSetSound() {
      var isSoundOn = localStorage.getItem("isSoundOn");
      if (isSoundOn == 1 || isSoundOn == null) {
        isSoundOn = 0;
      } else {
        isSoundOn = 1;
      }
      localStorage.setItem("isSoundOn", isSoundOn);
      this.isSoundOn = isSoundOn == 1 || isSoundOn == null;
    },
  },
  created() {
    var isSoundOn = localStorage.getItem("isSoundOn");
    this.isSoundOn = isSoundOn == 1 || isSoundOn == null;
  },
});
