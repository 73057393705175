// register component as global
// define component naming with kebab-case
const videobannerCmp = Vue.component("video-banner-component", {
  template: "#videoBannerTemplate",
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
  },
});
